.bg-black {
  background-color: #000000;
}

.text-orange {
  color: #FFA500;
}

.graph-container {
  width: 100%;
  height: 100px;
  position: relative;
}

.graph-svg {
  width: 100%;
  height: 100%;
}

.graph-path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  transition: stroke-dashoffset 2s ease-in-out;
}

.graph-svg.animate .graph-path {
  stroke-dashoffset: 0;
}

@media (max-width: 992px) {
  .graph-container {
    height: 80px;
  }
}

.bg-custom {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-blend-mode: overlay;
  height: 100% !important;
}

/* .bg-black-custom {
  background-color: #00000061;
} */

.h-400 {
  height: 450px !important;
}

@media screen and (max-width: 480px) {
  .h-400 {
    height: auto !important;
  }
}


/* General Styling for bg-custom */
.bg-custom {
  position: relative; /* Ensure child elements are positioned relative to the section */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Align to the top */
}

/* Specific Section (#madina transportation) */
#madina\ transportation {
  position: relative;
  padding-top: 80px; /* Add top padding to adjust the space */
}

/* Text Section Inside SingleSection */
#madina\ transportation .SingleSection {
  position: absolute;
  bottom: 50px; /* Adjust as per your requirement */
  left: 20px; /* Or use percentages to make it dynamic */
  z-index: 2; /* Ensure it appears above the background */
}

/* Mobile-Specific View */
@media screen and (max-width: 768px) {
  #madina\ transportation .SingleSection {
      bottom: 20px; /* Adjust for mobile */
      left: 10px;
      padding: 10px;
      font-size: 14px; /* Optional: Smaller text for mobile */
  }
}
/* @media screen and (min-width: 768px) { */
/* .cursor {
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  border-radius: 50%;
  background: transparent;
  pointer-events: none;
  z-index: 111;
  border: 1px solid #fff;
  transition: all 0.2s ease-out;
  animation: moveCursor1 .5s infinite alternate;
}

.expand {
  background: transparent;
  animation: moveCursor2 .5s forwards;
  border: 1px solid yellow;
}

@keyframes moveCursor1 {
  from {
      transform: scale(1);
  }

  to {
      transform: scale(.8);
  }
}

@keyframes moveCursor2 {
  0% {
      transform: scale(1);
  }

  50% {
      transform: scale(2.5);
  }

  100% {
      transform: scale(1);
      opacity: 0;
  }
} */
@media screen and (min-width: 768px) {
body{
  cursor: none;
}
/* } */
body:hover #custom-cursor {
  opacity: 1;
}

#custom-cursor {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: white;
  position: fixed;
  top: 0;
  opacity: 0;
  pointer-events: none;
  mix-blend-mode: difference;
  transition: transform 500ms;
}

#custom-cursor::after {
  content: "";
  border-radius: 50%;
  position: absolute;
  top: -8px;
  left: -8px;
  border: 1px solid white;
  width: 16px;
  height: 16px;
}

#custom-cursor.zoom {
  transform: scale(3);
}
}


.fs-4 {
    font-size: calc(0.9rem + 0.2vw) !important;
}

@media (max-width: 768px) {
  .contact-row {
      flex-direction: column; /* Stack items vertically */
      align-items: flex-start; /* Align text to the left */
  }
  .contact-item {
      margin-bottom: 20px; /* Add spacing between items */
  }
.contact-de-1{
  text-align: start !important;
}
.fs-4 {
  font-size: 15px !important;
}
}

.contact-de-1{
  margin-top:14px !important;
}

