/* General Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #000;
  color: #fff;
}

.container-fluid {
  padding: 80px 15px;
}

/* Navbar Styles */
.navbar {
  transition: all 0.3s;
}

.navbar.hide {
  transform: translateY(-100%);
}

.logo {
  width: 100px;
}

.offcanvas-header .btn-close {
  filter: invert(1);
}

.cursor-pointer:hover {
  cursor: pointer !important;
}

/* Hero Section */
/* Common styles for all screen sizes */


/* For medium screens (tablets and above) */
@media (min-width: 768px) {
  .hero-section {
    background: url('../public/assets/img/hero.jpg'), rgba(0, 0, 0, 0.42);
  }
}

/* For mobile screens (below 768px) */
@media (max-width: 767px) {
  .hero-section {
    background: url('../public/assets/img/hero.jpg'), rgba(0, 0, 0, 0.463);
  }
}

.hero-section {
  background-size: cover !important;
  background-blend-mode: overlay;
  background-position: center !important;
  height: 100vh;
  background-repeat: no-repeat !important;
}

/* For medium screens (tablets and above) */
@media (min-width: 768px) {
  #page-hero {
    background: url('../public/assets/img/page-hero-lg.png'), rgba(0, 0, 0, 0.42);
  }
}

/* For mobile screens (below 768px) */
@media (max-width: 767px) {
  #page-hero {
    background: url('../public/assets/img/page-hero.png'), rgba(0, 0, 0, 0.463);
  }
}

#page-hero{
  background-size: cover !important;
  background-blend-mode: overlay;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

/* Animated Text Styles */
.animated-text-container {
  overflow: hidden;
}

.animated-text {
  font-size: 2rem;
  font-weight: bold;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.8s ease;
}

.animated-text.left {
  transform: translateX(-50px);
}

.animated-text.right {
  transform: translateX(50px);
}

.animated-text.left.animate,
.animated-text.right.animate {
  opacity: 1;
  transform: translateX(0);
}

/* Section Backgrounds */
.journey-section,
.powerhouse-section,
.brand-experience-section,
.international-events-section,
.ceremonies-shows-section {
  background-size: cover;
  background-position: center;
}

/* Graph Styles */
.graph-container {
  height: 300px;
  position: relative;
  margin: 50px 0;
}

.graph-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
}

.graph-point {
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  transform: scale(0);
  transition: all 0.5s ease-out;
}

.graph-container.animate .graph-point:nth-child(2) {
  left: 25%;
  height: 100px;
  transform: scale(1);
}

.graph-container.animate .graph-point:nth-child(3) {
  left: 50%;
  height: 200px;
  transform: scale(1);
}

.graph-container.animate .graph-point:nth-child(4) {
  left: 75%;
  height: 150px;
  transform: scale(1);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .animated-text {
    font-size: 1.5rem;
  }
  .fs-4 {
    font-size: calc(1.1rem + 0.2vw) !important;
  }
}

.fs-4 {
  font-size: calc(0.9rem + 0.2vw) !important;
}