%offcanvas-css-vars {
    --offcanvas-color: #ffffff;
    --offcanvas-bg: #ff0000;
}

$body-bg: #000000;
$primary: #0A1E3F;
$body-color: #FFFFFF;
$offcanvas-bg: #e5d6d67a;
$offcanvas-padding-y: 10px;


@import '~bootstrap/scss/bootstrap.scss';

.fs-4 {
    font-size: calc(0.9rem + 0.2vw) !important;
}

@media (max-width: 768px) {
  .fs-4 {
    font-size: calc(0.8rem + 0.1vw) !important;
  }
  
  }